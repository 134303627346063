<template>
  <div class="race-info-container">
    <v-card
      class="white--text"
      color="secondary"
      :width="$vuetify.breakpoint.mobile ? 350 : 600"
    >
      <v-card-title>
        Registration Is Open!
      </v-card-title>

      <v-card-text class="white--text">
        <v-btn
          to="/register"
          color="primary"
        >
          Click here to register!!
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card
      color="secondary"
      class="white--text"
      :width="$vuetify.breakpoint.mobile ? 350 : 600"
    >
      <v-img src="@/assets/shirt.png" />
    </v-card>
    <v-card
      color="secondary"
      class="white--text"
      :width="$vuetify.breakpoint.mobile ? 350 : 600"
    >
      <v-img src="@/assets/pennant.png" />
    </v-card>
    <v-card
      class="white--text"
      color="secondary"
      :width="$vuetify.breakpoint.mobile ? 350 : 600"
    >
      <b>Start Time:</b> November 24, 2022 9:00AM<br>
      <b>Address:</b> 78 Mapleview Rd Buffalo, NY 14225 (<a
        href="https://goo.gl/maps/aWhVxF1GKDuwn5hq5"
        target="_blank"
      >Directions</a>)<br>
      <b>After Party:</b> Fuck yeah!<br><br>
      <h2>8K Race Map:</h2><br>

      <v-img
        src="@/assets/race-map-8k.png"
        :width="$vuetify.breakpoint.mobile ? 350 : 600"
      />
      (<a
        href="https://www.google.com/maps/dir/78+Mapleview+Rd,+Buffalo,+NY+14225-1523,+USA/42.9449768,-78.7673333/McPartlan's+Corner/42.9495137,-78.7801272/42.9408653,-78.7829597/@42.9427826,-78.7759494,15z/data=!4m32!4m31!1m15!1m1!1s0x89d3732fbed7d353:0x8794e531641082c8!2m2!1d-78.7832169!2d42.940448!3m4!1m2!1d-78.7823325!2d42.9337473!3s0x89d37332ed91caf7:0xad57a419b1cabe26!3m4!1m2!1d-78.7694209!2d42.9331909!3s0x89d3734a88cd2c87:0xf055f78e3a861363!1m0!1m5!1m1!1s0x89d373681d332ecf:0x73e72935307da05f!2m2!1d-78.759574!2d42.949361!1m5!3m4!1m2!1d-78.7840232!2d42.9484492!3s0x89d373230c06942f:0x1ecefe0ee0739db1!1m0!3e2?hl=en"
        target="_blank"
      > Click here for Google Map</a>)
      <h2>5K Race Map:</h2><br>

      <v-img
        src="@/assets/race-map-5k.png"
        :width="$vuetify.breakpoint.mobile ? 350 : 600"
      />
      (<a
        href="https://www.google.com/maps/dir/78+Mapleview+Rd,+Buffalo,+NY+14225-1523,+USA/42.9337637,-78.7825494/42.9353302,-78.7699064/42.9449653,-78.7719848/42.9406184,-78.7828942/@42.940742,-78.7754302,16z/data=!4m22!4m21!1m5!1m1!1s0x89d3732fbed7d353:0x8794e531641082c8!2m2!1d-78.7832146!2d42.940448!1m5!3m4!1m2!1d-78.769605!2d42.9328505!3s0x89d3734a84e1765b:0x88ebc10b947280c8!1m0!1m5!3m4!1m2!1d-78.7837618!2d42.9448055!3s0x89d37324e5407ca9:0x4627f7a19df2d5b6!1m0!3e2"
        target="_blank"
      > Click here for Google Map</a>)

      <v-card-title>
        Race Map
      </v-card-title>

      <v-card-subtitle class="white--text">
        Enjoy a beautiful scenic run amongst some of the sights of world reknowned Cheektowaga, NY!
      </v-card-subtitle>

      <v-divider />

      <v-card-text class="white--text">
        See some of the best sights Western New York has to offer,
        such as:
        <ul>
          <li>
            The concrete retaining walls alongside the Kensington Expressway!
          </li>
          <li>
            Not one, but two churches!
          </li>
          <li>
            Sprawling views of the 90-290 Interchange!
          </li>
          <li>
            The concrete retaining walls alongside the Kensington Expressway!
          </li>
          <li>
            Try not to trip over one of our many, many potholes.
          </li>
          <li>
            Cross the finish line directly acrossed the street from Cleveland Hill High School,
            which is famous
            for it's unique architecture and a <a
              href="https://en.wikipedia.org/wiki/1954_Cleveland_Hill_School_District_fire"
              target="_blank"
            >1954 explosion that killed 15 children!</a>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'RaceInfo',
};
</script>

<style lang="scss" scoped>
.race-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding: 50px;
  > * {
    padding: 30px;
    margin-bottom: 30px;
  }
}
</style>
